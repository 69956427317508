import {useEffect} from 'react'
import {useTrustArcConsent} from '../trustarc'

export function useBazaarVoice(): void {
	const cookieValue = useTrustArcConsent()
	useEffect(() => {
		const shouldTrack =
			cookieValue === 'ALL_COOKIES' || cookieValue === 'ADVERTISE_COOKIES' // need to enable tracking mode for these types of cookies
		if (window.BV) {
			window.BV.cookieConsent.setConsent({
				BVBRANDID: shouldTrack,
				bv_metrics: shouldTrack,
				BVBRANDSID: shouldTrack,
			})
		}
	}, [cookieValue])
}
