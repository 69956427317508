import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'

export type BazaarVoiceContentItem = IContentItem<{
	productId: Elements.TextElement
	widgetPositioning: Elements.MultipleChoiceElement
}>

export const BazaarVoiceBlock: Block<BazaarVoiceContentItem> = ({block}) => {
	const widgetPositioning = block.elements.widgetPositioning[0]?.codename as
		| 'left'
		| 'right'
		| 'centre'
	const widgetPositioningClass = {
		left: 'flex justify-start',
		right: 'flex justify-end',
		centre: 'flex justify-center',
	}

	return (
		<div
			className={`w-full ${widgetPositioningClass[widgetPositioning]}`}
			data-bv-product-id={block.elements.productId}
			data-bv-show="reviews"
		/>
	)
}
