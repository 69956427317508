import {useEffect, useState} from 'react'
import App from 'next/app'
import type {AppContext, AppProps, NextWebVitalsMetric} from 'next/app'
import {ReactQueryDevtools} from 'react-query/devtools'
import {QueryClient, QueryClientProvider} from 'react-query'
import Layout from '@/components/layout'
import axios from 'axios'
import '../styles/globals.css'
import Script from 'next/script'
import {SmartLinkProvider} from '@/_new-code/services/kontent-ai/preview-mode/smart-link'
import {PageProps} from '@/pages/[[...slug]]'
import {useMikMak} from '@/_new-code/services/mikmak'
import {AuthProvider} from '@/_new-code/products/auth/auth-provider'
import {GoogleTagManager} from '@next/third-parties/google'
// import {cspString} from '../csp.mjs'

// Needed for rich-text-renderer
import findLastIndex from 'array.prototype.findlastindex'
import {useTrustArcConsent} from '@/_new-code/services/trustarc'
import {disablePageView, enablePageView} from '@/services/client-logger'
import {useBazaarVoice} from '@/_new-code/services/bazaar-voice'
findLastIndex.shim()

export function reportWebVitals(metric: NextWebVitalsMetric) {
	const body = JSON.stringify(metric)
	const url = '/api/report/web-vitals'

	if (navigator.sendBeacon) {
		navigator.sendBeacon(url, body)
	} else {
		axios.post(url, {body, keepalive: true})
	}
}

const Application = ({
	Component,
	pageProps: rawPageProps,
	router,
}: AppProps<PageProps>): JSX.Element => {
	const pageProps = rawPageProps as PageProps
	if (router?.locale === 'null') return <p>404 - Not found</p>

	const gtmContainerID = pageProps.globalConfig.elements.gtmContainerId.trim()
	const trustBanner = pageProps.globalConfig.elements.trustArcBanner.trim()
	const country = pageProps.globalConfig.elements.country.trim()
	const language = pageProps.globalConfig.elements.language.trim()

	const mikMakAppId = pageProps.globalConfig.elements.appId || null
	useMikMak(mikMakAppId)
	useBazaarVoice()

	useEffect(() => {
		if (router?.locale === 'global') return () => {}
		const taScript = document.createElement('script')
		taScript.src = `https://consent.trustarc.com/notice?domain=${trustBanner}&c=teconsent&js=nj&noticeType=bb&text=true&country=${country}&language=${language}&gtm=1`
		document.head.appendChild(taScript)
		return () => taScript.remove()
	}, [router.locale])

	useEffect(() => {
		// Function to update lang attribute on the <html> tag during page load and on route change
		const updateLang = () => {
			const langHTMLTag = pageProps.globalConfig.elements.langHtmlTag
			if (langHTMLTag && document.documentElement.lang !== langHTMLTag) {
				document.documentElement.setAttribute('lang', langHTMLTag)
			}
		}
		const handleRouteChange = () => {
			updateLang()
		}
		router.events.on('routeChangeComplete', handleRouteChange)

		return () => {
			router.events.off('routeChangeComplete', handleRouteChange)
		}
	}, [])

	// Create a query client
	const [queryClient] = useState(() => new QueryClient())

	const cookieConsent = useTrustArcConsent()

	useEffect(() => {
		if (
			cookieConsent === 'ALL_COOKIES' ||
			cookieConsent === 'FUNCTIONAL_COOKIES'
		) {
			enablePageView()
		} else {
			disablePageView()
		}
	}, [cookieConsent])

	return (
		<>
			{/* This library is used for autocomplete functionality in the CDC Screenset */}
			<Script
				type="text/javascript"
				src="https://cdn.jsdelivr.net/npm/@tarekraafat/autocomplete.js@10.2.7/dist/autoComplete.min.js"
				async
				strategy="afterInteractive"
			/>
			{gtmContainerID && router?.locale !== 'global' ? (
				<GoogleTagManager gtmId={gtmContainerID} />
			) : null}

			<SmartLinkProvider>
				<QueryClientProvider client={queryClient}>
					<AuthProvider {...pageProps}>
						<Layout {...pageProps}>
							<Component {...pageProps} />
						</Layout>
					</AuthProvider>
					<ReactQueryDevtools initialIsOpen={false} />
				</QueryClientProvider>
			</SmartLinkProvider>
		</>
	)
}

Application.getInitialProps = async (appContext: AppContext) => {
	const appProps = await App.getInitialProps(appContext)
	if (appContext?.router?.locale === 'null') return {notFound: true}
	return {...appProps}
}

export default Application
